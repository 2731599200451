import { Permissions } from 'cc-permissions-utils'

import { TEST } from './environments'

const permissions: Permissions = {
  authorization: {
    create: (env = TEST): string => `Authorization.create.${env}`
  },
  charge: {
    create: (env = TEST): string => `Charge.create.${env}`
  },
  activation: {
    create: (): string => 'account.activation.create',
    get: (): string => 'account.activation.get'
  },
  businessUnits: {
    create: (env = TEST): string => `Account.apps.create.${env}`,
    get: (env = TEST): string => `Account.apps.get.${env}`,
    update: (env = TEST): string => `Account.apps.update.${env}`,
    getKeys: (env = TEST): string => `Account.apps.keys.get.${env}`,
    replaceKeys: (env = TEST): string => `Account.apps.keys.replace.${env}`
  },
  providerConfigurations: {
    create: (env = TEST): string => `Account.provider-config.create.${env}`,
    get: (env = TEST): string => `Account.provider-config.get.${env}`,
    update: (env = TEST): string => `Account.provider-config.update.${env}`,
    delete: (env = TEST): string => `Account.provider-config.delete.${env}`
  },
  webhooks: {
    get: (env = TEST): string => `Account.webhook-config.get.${env}`,
    create: (env = TEST): string => `Account.webhook-config.create.${env}`,
    update: (env = TEST): string => `Account.webhook-config.update.${env}`
  },
  payments: {
    get: (env = TEST): string => `Payment.get.${env}`,
    create: (env = TEST): string => `Payment.create.${env}`,
    capture: (env = TEST): string => `Payment.capture.create.${env}`,
    refund: (env = TEST): string => `Payment.refund.create.${env}`,
    void: (env = TEST): string => `Payment.void.create.${env}`,
    resendWebhook: (env = TEST) => `Account.webhooks.payments.create.${env}`
  },
  paymentMethod: {
    create: (env = TEST): string => `PaymentMethod.create.${env}`
  },
  customer: {
    create: (env = TEST): string => `Customer.create.${env}`,
    get: (env = TEST): string => `Customer.get.${env}`
  },
  token: {
    create: (env = TEST): string => `Token.create.${env}`
  },
  analytics: {
    payments: (env = TEST): string => `Analytics.Payment.${env}`
  },
  decisionEngine: {
    flows: {
      get: (env = TEST): string => `Smart-Routing.flows.get.${env}`,
      create: (env = TEST): string => `Smart-Routing.flows.create.${env}`,
      update: (env = TEST): string => `Smart-Routing.flows.update.${env}`
    }
  },
  reports: {
    generate: (env = TEST): string => `Reports.transactionexport.generatedownload.${env}`,
    manage: (env = TEST): string => `Reports.transactionexport.managetemplates.${env}`,
    manageSchedules: (env = TEST): string => `Reports.transactionexport.manageschedules.${env}`
  },
  invoices: {
    search: (): string => 'account.invoices.search',
    download: (): string => 'account.invoices.download'
  },
  users: {
    create: (): string => 'Account.users.create',
    get: (): string => 'Account.users.get',
    update: (): string => 'Account.users.update',
    remove: (): string => 'Account.users.permissions.create',
    importExistingUser: (): string => 'Account.users.permissions.create',
    updateOtherUserPassword: (): string => 'Account.users.password.change'
  },
  sftp: {
    get: (): string => 'account.sftp.users.get',
    create: (): string => 'account.sftp.users.create',
    update: (): string => 'account.sftp.users.update',
    delete: (): string => 'account.sftp.users.delete'
  },
  gdpr: {
    get: (): string => 'personal-data.get'
  },
  balanceConfiguration: {
    get: (env: string): string => `account.balance-configurations.get.${env}`
  },
  balances: {
    get: (env: string): string => `account.balances.get.${env}`
  },
  balancesOperations: {
    get: (env: string): string => `account.balances-operations.search.${env}`
  },
  payouts: {
    get: (env: string): string => `account.payouts.get.${env}`,
    update: (env: string): string => `account.payouts.update.${env}`,
    create: (env: string): string => `account.payouts.create.${env}`,
    search: (env: string): string => `account.payouts.search.${env}`
  },
  payoutConfigurations: {
    activate: {
      get: (): string => 'operations.payout-configurations.activate.get',
      create: (): string => 'operations.payout-configurations.activate.create'
    }
  },
  operations: {
    balancesConfigurationsSearch: {
      get: (): string => `operations.balances-configurations.search`
    },
    balances: {
      get: (): string => `operations.balances.get`
    },
    balanceLocks: {
      activate: (): string => `operations.risk.balance-lock.activate`,
      deactivate: (): string => `operations.risk.balance-lock.deactivate`,
      get: (): string => `operations.risk.balance-lock.get`
    },
    balanceOperationsSearch: {
      get: (): string => `operations.balances-operations.search`
    },
    payouts: {
      get: (): string => `operations.payouts.get`,
      update: (): string => `operations.payouts.update`,
      create: (): string => `operations.payouts.create`,
      search: (): string => `operations.payouts.search`,
      packs: {
        get: (): string => `operations.payouts.packs.get`,
        approve: (): string => `operations.payouts.packs.approve`,
        execute: (): string => `operations.payouts.packs.execute`,
        update: (): string => `operations.payouts.packs.update`,
        search: (): string => `operations.payouts.packs.search`
      }
    },
    settlements: {
      templates: (): string => `operations.settlements.templates`,
      reports: (): string => `operations.settlements.reports`
    },
    invoices: {
      search: (): string => 'operations.invoices.search',
      download: (): string => 'operations.invoices.download'
    },
    reviews: {
      create: (): string => 'operations.reviews.create',
      get: (): string => 'operations.reviews.get',
      update: (): string => 'operations.reviews.update'
    }
  },
  support: {
    accounts: {
      activate: {
        create: (): string => 'support.accounts.activate.create'
      }
    }
  },
  localization: {
    screens: {
      get: (): string => 'localization.screens.get'
    }
  },
  paymentLinks: {
    get: (): string => 'account.payment-links.get',
    create: (): string => 'account.payment-links.create'
  },
  fraudManager: {
    configurations: {
      get: (): string => 'operations.fraud-configurations.get',
      create: (): string => 'operations.fraud-configurations.create'
    }
  }
}
export default permissions
